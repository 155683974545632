import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { MqttComponent } from './mqtt.component';

const routes: Routes = [
  {
    path: '',
    component: MqttComponent,
    children: [
      {
        path: 'brokers-mqtt',
        loadChildren: () =>
          import('./brokers/brokers.module').then((m) => m.BrokersModule),
      },
      {
        path: 'envios-mqtt',
        loadChildren: () =>
          import('./requests/requests.module').then((m) => m.RequestsModule),
      },
      {
        path: 'recepciones-mqtt',
        loadChildren: () =>
          import('./receptions/receptions.module').then((m) => m.ReceptionsModule),
      },
    ],
  },
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  // { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MqttRoutingModule { }
