import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { MqttComponent } from './mqtt.component';

// Internal modules
import { MqttRoutingModule } from './mqtt-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StaticModule } from '../static/static.module';


@NgModule({
  declarations: [
    MqttComponent
  ],
  imports: [
    CommonModule,

    //Internal Modules
    MqttRoutingModule,
    SharedModule,
    StaticModule
  ]
})
export class MqttModule { }
