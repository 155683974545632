import { Component } from '@angular/core';

@Component({
  selector: 'lockbin-mqtt',
  templateUrl: './mqtt.component.html',
  styles: [
  ]
})
export class MqttComponent {

}
